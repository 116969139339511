<template>
  <div class="PageBox">
<div class="">
  <div class="header_bg">
    <div class="flex_sb" style="font-size: 16px;padding: 5px">
      <div class="title_user">
        <span style="padding: 10px;font-weight: bold;color: var(--nav-bar-font)">{{$t('user.default[0]')}}</span>
      </div>
      <div></div>
      <van-col span="12" style="text-align: right;">
        <van-icon name="./skin/setting.png" size="25" @click="onClickRight(0)"/>
        <van-icon name="./skin/notice.png" size="25" @click="onClickRight(1)"/>
      </van-col>
    </div>

    <div class="infoDiv">
      <img class="head" :src="`./static/head/${UserInfo.header}`" />
      <div style="text-align: center">
        <div class="userName">{{ UserInfo.username.substring(0,15) }}</div>
        <div class="">
          <span class="vip">VIP{{ UserInfo.vip_level }}</span>
          <span class="xx"> {{ $t("wallet.default[13]") }}</span>
        </div>
      </div>
      <!-- {{ $t("user.default[2]") }}:{{ UserInfo.idcode }} -->
    </div>

    <div class="tool flex_sb flex_ac">
      <div>
        <div class="text1">{{ $t("hall.default[1]") }}(USDT)</div>
        <div class="text2">{{ Number(UserInfo.balance).toFixed(2) }}</div>
        <div class="text3"></div>
      </div>
      <div class="shu"></div>
      <div>
        <div class="text1">{{ $t("hall.default[1]") }}({{InitData.usdtinfo.sign}})</div>
        <div class="text3" style="font-weight: normal;color:var(--nav-bar-font)">{{ $Util.formatNum(Number(UserInfo.balance2).toFixed(2)) }} </div>
        <div class="text3"></div>
      </div>
    </div>

    <div class="user_blank flex_ac flex_sb">
      <div class="user_item flex_ac flex_center" @click="$router.push('/user/mixrecharge')">
        <img src="~@/assets/img/new/user_cz1.png" alt="">
        {{ $t("wallet.default[1]") }}</div>
      <div class="shu1"></div>
      <div class="user_item flex_ac flex_center" @click="gotoWithdraw">
        <img src="~@/assets/img/new/user_tx1.png" alt="">{{ $t("withdraw2[0]") }}</div>
    </div>

    <!--div class="Panel" style="display: flex;margin: 15px;text-align: center">
      <div style="display: block">
        <div>
          {{(Number(UserInfo.total_recharge)).toFixed(3)}}
        </div>
        <div>
          {{$t('wallet.default[10]')}}
        </div>
      </div>
      <div style="display: block">
        <div>
          {{(Number(UserInfo.total_recharge)).toFixed(3)}}
        <div>
          {{sellDesc}}
        </div>
      </div>
      </div>
      <div style="display: block">
        <div>
          {{(Number(UserInfo.total_commission)).toFixed(3)}}
        </div>
        <div>
          {{commDesc}}
        </div>
      </div>
    </div-->
    <!-- 最后一个框 -->
    <div class="tool02">
      <van-cell
          size="large"
          :title="$t('user.menu[0]')"
          to="/user/wallet"
          center
          is-link
      >
        <template #icon>
          <van-icon style="height:26px;margin-right:10px" name="./skin/user_nav01.png" alt=""/>
        </template>
      </van-cell>
      <van-cell
          size="large"
          :title="$t('user.menu[1]')"
          to="/user/teamReport"
          center
          is-link
      >
        <template #icon>
          <van-icon style="height:26px;margin-right:10px" name="./skin/user_nav02.png" alt=""/>
        </template>
      </van-cell>
      <van-cell
          size="large"
          :icon="`./static/icon/bind.png`"
          :title="$t('user.menu[2]')"
          to="/user/bindAccount"
          center
          is-link
      >
        <template #icon>
          <van-icon style="height:26px;margin-right:10px" name="./skin/user_nav03.png" alt=""/>
        </template>
      </van-cell>
      <van-cell
          size="large"
          :icon="`./static/icon/account.png`"
          :title="$t('user.menu[3]')"
          to="/user/fundRecord"
          center
          is-link
      >
        <template #icon>
          <van-icon style="height:26px;margin-right:10px" name="./skin/user_nav04.png" alt=""/>
        </template>
      </van-cell>
      <van-cell
          size="large"
          :icon="`./skinlscc/user_introduce.png`"
          :title="$t('user.menu[4]')"
          to="/help"
          center
          is-link
      >
        <template #icon>
          <van-icon style="height:26px;margin-right:10px" name="./skin/user_nav05.png" alt=""/>
        </template>
      </van-cell>
      <van-cell
          size="large"
          :icon="`./skinlscc/user_introduce.png`"
          :title="$t('user.menu[5]')"
          @click="$Model.Logout()"
          center
          is-link
      >
        <template #icon>
          <van-icon style="height:26px;margin-right:10px" name="./skin/user_nav06.png" alt=""/>
        </template>
      </van-cell>
    </div>
    <!--div class="centerPanel" style="padding: 30px 10px">
    <van-button @click="$Model.Logout()">
      {{ $t('user.menu[5]') }}
    </van-button>
    </div-->
    <div style="height: 80px"></div>
  </div>
</div>
<Footer v-if="$route.name == 'user'" />
<van-popup
    v-model="showMsg"
    style="background: transparent; width: 90%; text-align: center"
    @closed="$parent.isNotice = true"
>
  <div class="">
    <dl class="ScrollBox NoticePopup" style="height:450px;background-color: var(--panel_color)">
      <dt class="popup_title" style="color: #fff">
        <div>
              <span>{{
                  msg.title
                }}</span>
        </div>
      </dt>
      <dd v-html="msg.content" style="color: #fff;word-break: break-word"></dd>
    </dl>
  </div>
  <a class="close" href="javascript:;" @click="showMsg = false">
    <img src="~@/assets/img/pupup_false.png" />
  </a>
</van-popup>
</div>
</template>

<script>
import config from "@/config";

const AppDown = config.AppDown;
export default {
  name: "User",
  components: {},
  props: [],
  data() {
    return {
      statisticalData: {},
      buttonlist:[
        {url:"/user/wallet",title:this.$t('user.menu[0]'),icon:"./skinmlink/user_wallet.png"},
        {url:"/user/teamReport",title:this.$t('user.menu[1]'),icon:"./skinmlink/user_team.png"},
        {url:"/user/bindAccount",title:this.$t('user.menu[2]'),icon:"./skinmlink/user_bind.png"},
        {url:"/user/fundRecord",title:this.$t('user.menu[3]'),icon:"./skinmlink/user_account.png"},
        {url:"/help",title:this.$t('user.menu[4]'),icon:"./skinlscc/user_introduce.png"},
      ],
      uid: "", //用户ID
      InferiorList: "",
      show_logout:false,
      sellDesc:'',
      agent_type:0,
      commDesc:'',
      userInfo:{},
      showMsg:false,
      msg:{"title":'aaa',"content":'eee'},
    };
  },
  computed: {},
  watch: {},
  created() {
    this.sellDesc = this.$t('wallet.default[11]');
    this.commDesc = this.$t('wallet.default[12]')+"("+this.InitData.usdtinfo.sign+")";

    var token = localStorage.getItem("Token");

    var aa = "aaaaa";
    // for (var i=0;i<1000;i++){
    //   aa += "aaaaa";
    // }
    // this.msg['content'] = aa;
    // this.showMsg = true;
    this.$Model.GetMsg(data=>{
      if (data.code == 1) {
        this.msg = data['data'];
        if (this.msg['title'].length>0&&this.msg['content'].length>0){
          this.showMsg = true;
          var msg = this.msg['content'];
          for (var i=0;i<50;i++){
            msg = msg.replace("nowrap","");
          }
          this.msg['content'] = msg;
        }
      }
    });
    this.$Model.GetUserInfo((data) => {
      if (data.code == 1) {
        this.uid = data.info.uid;
        this.userInfo = data.info;
        // this.agent_type = data.info['agent_type'];
        this.$Model.getInferiorList({ uid: this.uid, token: token }, (data) => {
          this.InferiorList = data.list;
          console.log(data.list);
        });
      }
    });
    this.$Model.GetStatisticsInfo((data) => {
      if (data.code == 1) {
        this.statisticalData = data.info;
      }
    });
  },
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    copyCode() {
      var that = this;
      var copyValue = this.UserInfo.idcode;
    },
    gotoRe(){
      this.$router.push("/user/mixrecharge");
    },
    onClickRight(index){
      if (index==1){
        this.$router.push("/user/msglist");
      }else{
        this.$router.push("/user/info");
      }
    },
    gotoWithdraw(){
      this.$Model.TradeStatus((data) => {
        if (data['code']==1){
          let info = data['info'];
          if (info['shimingauth']==1){
            this.$router.push("/shimingauth");
            return ;
          }
          this.goWithPage();
        }
      })
    },
    goWithPage(){
      let info = this.userInfo;
      if (info.withdraw_body&&info.withdraw_body.length>0){
        this.msg['title'] = info.withdraw_title;
        this.msg['content'] = info.withdraw_body;
        this.showMsg = true;
        return;
      }
      let sold_count = this.userInfo['sold_count'];
      // let withdraw_order_count = this.userInfo['withdraw_order_count'];
      // let count = withdraw_order_count-sold_count;
      // if (count>0){
      //   let msg = this.$t('taskOrder2[4]')+count
      //   this.$Dialog.Toast(msg);
      //   return;
      // }

      this.$router.push('/user/withdraw');
    },
    goDown() {
      if (window.plus) {
        this.$router.push("/AppDown");
      } else {
        var appUrl = localStorage["AppUrl"];
        this.$Util.OpenUrl(appUrl);
      }
    },
    //复制成功
    clipboardSuccessHandler({ value, event }) {
      this.$toast({
        message: "Başarıyla kopyala",
      });
      console.log(value);
    },
    copyAddressError(e) {
      this.$toast({
        message: "复制失败",
      });
    },
  },
};
</script>
<style scoped>
.PageBox {
  background-color: var(--main-bg-color);
  padding-top:  0;
}
.ScrollBox{
}
.van-nav-bar {

}
.van-nav-bar>>>.van-nav-bar__title{
  font-weight: bold;
}

.header_bg {
  width: 100%;
  top: 0;
  z-index: 0;
  height:200px;

}
.title_user{
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.MyHeader {
  font-size: 19px;
  border-radius: 50%;
  background: pink;
  overflow: hidden;
  width: 72px;
  height: 72px;
}
.Mine_box {
  padding: 2px;
  overflow: hidden;
  margin-left: 10px;
  flex-shrink: 0;
  display: flex;
}
.MyHeaderStatus {
  width: max-content;
  padding: 0 7px;
  margin-top: 5px;
  margin-left: 10px;
  font-size: 12px;
  border-radius: 20px;
  background: #F3C13A;
  color:#fff;
  line-height: 25px;
  display: flex;
  text-align: center;
}

.user_vip {
  width: 44px;
  height: 19px;
  background: url(../../../public/static/icon/user_vip01.png) no-repeat;
  background-size: 100% 100%;
  margin-left: 13px;
  color: #4e51bf;
  font-style: italic;
  text-align: center;
  line-height: 19px;
  padding-right: 5px;
  margin: 5px 0 9px 13px;
  font-weight: bold;
}

.MyHeaderStatus .MyHeaderStatus_radius {
  width: 8px;
  height: 8px;
  background: #ff93a4;
  border-radius: 50%;
  display: inline-flex;
  margin-right: 2px;
}

.userName {
  font-size: 21px;
  color:#fff;
  font-family: emoji;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tool00 {
  width: 95%;
  border-radius: 10px;
  background-color: var(--panel_color);
  overflow: hidden;
  z-index: 99;
  padding: 0 5px;
  align-items: center;
  margin-left: 10px;
  margin-top: 50px;
  padding: 20px;
}

.tool01 {
  width: 100%;
  background-color: #F5F8FF;
  overflow: hidden;
  z-index: 99;
  align-items: center;
  display: flex;
  padding: 10px 20px;
}

.NoticePopup {
  background-size: 100% 10%;
  height: 0px;
}

.tool02 {
  width: calc(100% - 30px);
  z-index: 99;
  padding: 0 5px;
  align-items: center;
  margin-left: 15px;

  overflow: hidden;
  background: var(--panel_color);
  margin-top: 10px;
}
.van-cell:not(:last-child):after {
  left: 0;
  border-color:var(--line-color);
}

.tool02 >>> .van-cell {
  background: var(--panel_color);
  padding: 15px 10px;
  border: none;
  border-radius: 0px;
  border-bottom: 3px solid #28343D !important;
  /* background-color: #fff; */
}
.van-cell__value {
  color: red;
}
.close img {
  width: 36px;
  margin-top: 20px;
}

.infoDiv {
  padding: 30px;
  z-index: 10;
  text-align: center;
  background: transparent none repeat scroll 0% 0%;
}

.tool02 >>> .van-cell__title {
  color: var(--font_color);
}

.UserInfo .info .van-cell__title {
  font-size: 12px;
}

.UserInfo .info .van-cell__label {
  font-size: 14px;
  background: transparent none repeat scroll 0% 0%;
}

.UserInfo .info .van-cell__value {
  flex: none;
  line-height: 1.3;
  background: transparent none repeat scroll 0% 0%;
}

.UserInfo .info .van-cell__value a {
  text-align: center;
  /*color: #fff;*/
  display: block;
}

.top2 >>> .van-cell__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.top2 >>> .van-cell__title > span {
  font-size: 17px;
}
.tool02 >>> .van-cell__title .van-cell__label {
  padding: 0px 0;
  color: var(--font_subtitle);
  font-size: 12px;
}

.top3 >>> .van-icon img {
  width: 31px;
  height: 31px;
}

.top4 {
  padding: 0 15px;
}

.top4 >>> .van-cell {
  display: flex;
  justify-content: center;
  padding: 7px 16px;
}

.top4 >>> .van-cell .van-cell__title {
  flex: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* color:#fff; */
}
.top4_box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.top4_box >>> .van-cell {
  border-radius: 10px;
  padding: 0px 15px;
  margin: 0 auto;
  width: max-content;
  margin-bottom: 15px !important;
  margin-top: 15px !important;
}

.top4_box >>> .van-cell__title span {
  color: #fff !important;
  font-size: 13px !important;
}

.top4 >>> .van-cell__title span {
  font-size: 13px;
  color:#fff;
  font-family: Alibaba;
  font-weight: normal;
}

.top4 >>> .border_none {
  border-bottom: none;
  margin-bottom: 0;
}

.top4 >>> .padding_0 .van-cell {
  padding: 10px 0;
}
.Menu {
  /*border-top: 10px #0e1526 solid;*/
  margin-top: 5px;
  line-height: 22px;
}

.Menu >>> .van-cell {
  font-size: 16px;
  text-align: center;
  background-color: #fff;
  padding: 0px 0px;
}

.Menu >>> .van-cell__label {
  font-size: 16px;
  color: #fff;
  text-align: center;
}


.Menu >>> .van-grid-item__content {
  background: transparent none repeat scroll 0% 0%;
  padding: 16px 0px;
}
.btn {
  margin: 20px;
  background: #fff;
  border-radius: 10px;
  padding: 10px 0;
}


.info{
  padding: 10px 30px 20px 30px;

}
.head{
  height: 88px;
  width: 88px;
  border-radius: 50px;
  background-color: #4B9222;
}
.userName{
  font-size: 20px;
  color: #fff;
  margin-bottom: 6px;
}
.vip{
  background: url(~@/assets/img/new/vip.png) no-repeat;
  background-size: auto 100%;
  height: 20px;
  line-height: 20px;
  padding-left: 26px;
  color: #AB783F;
  font-size: 12px;
  font-weight: 600;
  width: 80px;
}
.xx{
  color: #f1f1f1;
}

.tool {
  width: 92%;
  border-radius: 0px;
  background-color: var(--panel_color);
  height: 140px;
  overflow: hidden;
  z-index: 99;
  display: flex;
  align-items: center;
  margin-left: 4%;
  padding: 20px 20px 40px;
  text-align: center;

}

.shu{
  height: 40px;
  width: 1px;
  background: #f1f1f1;
}
.text1{
  height: 16px;
  font-size: 14px;
  color: var(--font_subtitle);
}
.text2{
  font-weight: 600;
  font-size: 18px;
  color: var(--font_color);
  margin: 10px 0;
}
.text3{
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
  color: var(--font_color3);
}

.user_blank{
  margin: -30px 4% 0;
  height: 52px;
  border-radius: 30px;
  background:var(--button_bg);
  background-size: 100% 100%;
  color: #FFFFFF;
}
.user_blank img{
  height: 18px;
  margin-right: 4px;
}
.user_item{
  width: calc(50% - 2px);
  text-align: center;

}
.shu1{
  height: 20px;
  width: 1px;
  background: #E8DABD;
}
</style>

